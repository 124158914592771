import React from "react"
import styled from "styled-components"
import { device } from "../utils/devices"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Layout from "../components/layout"

const Wrapper = styled.div`
  width: 90vw;
  min-height: 100vh;
  color: white;
  display: flex;
  font-size: 16px;
  max-width: 800px;
  line-height: 1.6em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;

  ol {
    line-height: 2em;
  }

  a {
    color: white;
    text-decoration: none;
    font-weight: bold;
  }

  .nav-link {
    position: fixed;
    top: 1rem;
    left: 1rem;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    color: black;
  }
`

const Rules = () => (
  <Layout>
    <Wrapper>
      <SEO title="Rules" />
      <h2>DISCLAIMERS & RULES OF PLAY</h2>
      <p>
        Trap Karaoke LLC its hosts, contractors, assigns, agents, and sponsors
        aim to create a fun online user experience sharing publicly available
        and viral memes. Trap Karaoke LLC in no way asserts ownership or rights
        other than those granted by source public platforms and its users (i.e.
        facebook, twitter, instagram, tiktok, snapchat) to reshare, republish,
        and broadcast the images, videos, and memes displayed during and live
        stream.
      </p>
      <p>
        Participants who comment during the live stream accept and agree to be
        legally bound to the following rules of contest play:
      </p>
      <ol>
        <li>
          All livestreams will be conducted via Instagram Live utilizing the
          handle <a href="https://instagram.com/trapkaraoke">@trapkaraoke</a>
        </li>
        <li>
          All live streams are viewable and open to the public to watch and
          participate free of cost
        </li>
        <li>
          Any authorized Instagram user over the legal age of 18 may qualify for
          a cash prize by participating in the contest. No cash prize shall
          exceed $50 in value.
        </li>
        <li>
          Contests will take place in 60 to 90 second rounds in which a
          image/video will be displayed and users may offer captions via the
          comment function ("Rounds")
        </li>
        <li>
          Participants agree and warrant that all captions and comments are
          original works of authorship and do not infringe or violate the rights
          of any third party
        </li>
        <li>
          Participants agree and grant all rights to Trap Karaoke LLC to
          announce winning profile accounts, redisplay and utilize in any format
          submitted captions and comments{" "}
        </li>
        <li>
          The host in his/her sole discretion will select one (1) winner per
          Round{" "}
        </li>
        <li>
          Winner of each Round will be the person authoring the best caption
        </li>
        <li>
          All winners must have a valid CashApp account to receive any cash
          prize
        </li>
        <li>
          Any winner who is not present for selection at the end of a Round, is
          under the legal age of 18 or does not have a valid CashApp account
          shall forfeit any prize
        </li>
        <li>Contest void where prohibited</li>
      </ol>
      <p>
        By participating, you agree (a) to be bound by these Official Contest
        Rules; (b) as between you and Trap Karaoke, the decisions of Trap
        Karaoke are final on all matters relating to the Contest; (c) you are
        not participating on behalf of any employer or third party; (d) in the
        event that you do not comply with these rules, that you will be
        disqualified and your prize (if any) will be forfeited. ALL DECISIONS
        REGARDING THE APPROPRIATENESS OF SUBMITTED CAPTIONS AND COMMENTS SHALL
        BE AT THE SOLE DISCRETION OF TRAP KARAOKE LLC. Trap Karaoke LLC reserves
        the right to reject any caption or comment, in its sole discretion,
        based on the terms set forth herein as well as current internet
        standards and other terms of service policies established by utilized
        display platforms. Any waiver of any obligation hereunder by Trap
        Karaoke does not constitute a general waiver of any obligation to
        participants. Sponsor reserves the right to waive the Contest Content
        requirements set forth herein in its reasonable discretion. Trap Karaoke
        reserves the right, in its reasonable discretion, during or upon
        completion of a contest, to request that any winner resubmit his or her
        information which fails to comply with the rule requirements prior to
        disbursement of any prize. Any valuation of the prize(s) stated above is
        based on available information provided to Trap Karaoke, and the value
        of any prize awarded to a winner may be reported to the IRS as required
        by law. Each winner is solely responsible for reporting and paying any
        and all applicable federal, state, and local taxes, related to prize
        acceptance and use not specified herein.
      </p>
      <p>
        PARTICIPANT CREATED CONTENT POSTED TO THE WEBSITE WAS NOT EDITED BY
        SPONSOR AND IS THE VIEWS/OPINIONS OF THE INDIVIDUAL PARTICIPANT AND DOES
        NOT REFLECT THE VIEWS OF TRAP KARAOKE OR ITS SPONSORS IN ANY MANNER.
        Trap Karaoke LLC reserves the right to terminate the contest if in its
        sole judgment, the rules or the integrity of the contest have been
        violated or compromised in any way, intentionally or unintentionally by
        any person whether or not a winner in the contest. If you think that any
        content utilized in the contest infringes your intellectual property
        rights, <a href="mailto:legal@trapkaraoke.com">CLICK HERE</a> if you
        wish to report it, or contact us by mail at DMCA Designated Agent, c/o
        Legal Department, Trap Karaoke LLC, 1055 West 7th Street 33rd Floor PH
        Los Angeles Ca. 90017 or by email at{" "}
        <a href="mailto:legal@trapkaraoke.com">legal@trapkaraoke.com</a>. All
        issues and questions concerning the construction, validity,
        interpretation and enforceability of the contest official rules, or the
        rights and obligations of participants and Trap Karaoke LLC in
        connection with the contest, shall be governed by, and construed in
        accordance with, the laws of the State of Georgia, U.S.A. wherein
        participation in this contest constitutes agreement and waiver of venue,
        choice of law, or any effect of conflict of laws rules that may normally
        apply to controversies.
      </p>
    </Wrapper>
  </Layout>
)

export default Rules
